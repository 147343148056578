import React, { useEffect, useState, useReducer } from "react";
import {
    Header,
    Tabs,
    Box,
    Button,
    Flashbar,
    SpaceBetween,
    Grid,
    AppLayout,
    Popover,
} from '@amzn/awsui-components-react';
import "../../css/Exception.css"
import ExceptionSummary from "../common/ExceptionSummary";
import Summary from "../DS/DSSummary";
import { getAcknowledgeDataDSV2, getUserAlias, getQuickSightLinkMap, isObligationUnderReview } from "../../utils/utils";
import ObligationDetails from "../common/ObligationDetails";
import { modifyWorkflowStatus } from "../redux/exceptionAPIs";
import { IS_PAGE_AUTHORIZED } from "../../../../ApiPaths";
import '../../css/style.css';
import Adjustments from "../common/adjustment/Adjustments";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import { postMessage } from "../../../phastos/components/Slack/slackIntegrationAPIs";
import { Correspondence } from "../common/Correspondence";
import ViewAttachments from "../common/attachment/ViewAttachments";
import ErrorSummary from "./DSErrorSummary-V2.js";
import { OBLIGATION_STATUS, PHASTOS_OBLIGATION } from "../common/constants";
import { getExceptionTransactionDetails } from "../common/adjustment/constants/errorSummaryUtils";
import { RejectReturnModal } from "../common/RejectReturnModal";
import {APPROVER_ROLE, REVIEWER_ROLE} from "../../../../common/constants/constants";
import {API} from "aws-amplify";
import {getAPIInputContext} from "../../../../common/utils/apiUtils";
import ExceptionChecklists from "./components/ExceptionChecklists-V2";
import {triggerGetQuickSightEmbedUrl} from "../common/quicksightUtils";
import {
    GET_QUICKSIGHT_EMBED_URL_INITIAL_STATE,
    getQuicksightEmbedUrlInitialState, getQuicksightEmbedUrlReducer
} from "../redux/getQuicksightEmbedUrlReducer";

function Exception(props) {

    const {
        itemData,
        slackChannel,
        correspondenceMessages,
        correspondenceLoading,
        handleToolsChange,
        handleNewMessage,
        exceptionSummaryData,
        updateWorkflowStatus,
        updateWorkflowStatusDispatcher,
        domain
    } = props;

    const [exceptionData, setExceptionData] = useState();
    const stepsInitialState = getAcknowledgeDataDSV2(itemData?.acknowledgementMap);
    const [stepsState, setStepsState] = useState(stepsInitialState);
    const [isTransactionReviewLoading, setIsTransactionReviewLoading] = useState(false);
    const [isUnderReview, setIsUnderReview] = useState();
    const [approveButtonDisabled, setApproveButtonDisabled] = useState(false);
    const [obligationStatus, setObligationStatus] = useState();
    const [parentWorkflowId, setParentWorkflowId] = useState();

    const exceptionSummaryTableProp = [
        {
            'step': 'Summary',
            'status': stepsState.summaryStep?.completed ? 'Completed' : 'Pending',
            'person': stepsState.summaryStep.user,
            'date': stepsState.summaryStep?.updatedOn,
        },
        {
            'step': 'Checklists',
            'status': stepsState.checklistStep?.completed ? 'Completed' : 'Pending',
            'person': stepsState.checklistStep.user,
            'date': stepsState.checklistStep?.updatedOn,
        },
        {
            'step': 'Transaction review',
            'status': stepsState.transactionReviewStep?.completed ? 'Completed' : 'Pending',
            'person': stepsState.transactionReviewStep?.user,
            'date': stepsState.transactionReviewStep?.updatedOn,
        },
        {
            'step': 'Audit',
            'status': stepsState.auditStep?.completed ? 'Completed' : 'Pending',
            'person': stepsState.auditStep?.user,
            'date': stepsState.auditStep?.updatedOn,
        }
    ];

    const setStepStateHandler = async (step) => {
        const userAlias = await getUserAlias();
        setStepsState({
            ...stepsState,
            [step]: {completed: true, user: userAlias, updatedOn: new Date().toLocaleDateString()},
        });
    };

    const [transactionReviewEditMode, setTransactionReviewEditMode] = useState(true);

    const [userRole, setUserRole] = useState(REVIEWER_ROLE);

    useEffect(() => {
        getAPIInputContext().then(input => {
            input.body.resourceName = REVIEWER_ROLE;

            API.post("TWIN_API", IS_PAGE_AUTHORIZED, input).then(resp => {
                if (resp.status === 200) {
                    if(resp.isAuthorized === true){
                        setUserRole(REVIEWER_ROLE);
                    }
                }
            });
        });
        getAPIInputContext().then(input => {
            input.body.resourceName = APPROVER_ROLE;

            API.post("TWIN_API", IS_PAGE_AUTHORIZED, input).then(resp => {
                if (resp.status === 200) {
                    if(resp.isAuthorized === true){
                        setUserRole(APPROVER_ROLE);
                    }
                }
            });
        });
    }, [])

    useEffect(() => {
        setParentWorkflowId(itemData?.parentWorkflowId);
        setObligationStatus(itemData?.status);
        setStepsState(getAcknowledgeDataDSV2(itemData?.acknowledgementMap));
        setExceptionData(itemData?.exceptionData);
        setIsUnderReview(isObligationUnderReview(itemData?.status));
    }, [itemData]);


    const [quicksightEmbedLinks, quickSightEmbedLinksDispatcher] = useReducer(getQuicksightEmbedUrlReducer,
        GET_QUICKSIGHT_EMBED_URL_INITIAL_STATE, getQuicksightEmbedUrlInitialState);
 
    const quickSightLinkMap = getQuickSightLinkMap(exceptionData);
 
    useEffect(() => {
        triggerGetQuickSightEmbedUrl(quickSightLinkMap,
            itemData?.obligationName, quicksightEmbedLinks, quickSightEmbedLinksDispatcher);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Send for Approval

    const isSendForApproveButtonDisabled = () => {
        return !(
            isUnderReview
            && stepsState.summaryStep.completed && stepsState.checklistStep.completed
            && stepsState.insightStep.completed && stepsState.transactionReviewStep.completed 
            && stepsState.auditStep.completed
        );
    };

    const generateUpdatingStatusRequest = (statusValue) => {
        return {
            parentWorkflowId: itemData?.parentWorkflowId,
            status: statusValue,
            acknowledgementStructureMap: {},
        }
    }

    const sendForApproveButtonClickHandler = () => {
        const onSuccess = async () => {
            const userAlias = await getUserAlias();
            postMessage(slackChannel, `${userAlias}@ has initiated the approval flow.`);
            setApproveButtonDisabled(true);
            setObligationStatus(OBLIGATION_STATUS.UnderApproval)
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher,
            generateUpdatingStatusRequest(OBLIGATION_STATUS.UnderApproval),
            onSuccess);
    };

    // Approve Return

    const isApproveButtonDisable = () => {
        return !(
            obligationStatus === OBLIGATION_STATUS.UnderApproval 
            && checklistState.approver.option1 && checklistState.approver.option2 
            && checklistState.approver.option3 && checklistState.approver.option4
        );
    };

    const approveButtonClickHandler = (event) => {
        event.stopPropagation();
        const onSuccess = async () => {
            const userAlias = await getUserAlias();
            postMessage(slackChannel, `${userAlias}@ has approved the return.`);
            setObligationStatus(OBLIGATION_STATUS.UnderFiling)
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher,
            generateUpdatingStatusRequest(OBLIGATION_STATUS.UnderFiling),
            onSuccess);
    };

    // Reject Return

    const [rejectConfirmModal, setRejectConfirmModal] = useState(false);

    const rejectButtonClickHandler = (event) => {
        event.stopPropagation();
        setRejectConfirmModal(true);
    };

    const rejectConfirmHandler = (rejectReason) => {
        const onSuccess = async () => {
            const userAlias = await getUserAlias();
            postMessage(slackChannel, `${userAlias}@ has rejected the return for the following reason: \n"${rejectReason}"`);
            setObligationStatus(OBLIGATION_STATUS.UnderReview)
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher,
            generateUpdatingStatusRequest(OBLIGATION_STATUS.UnderReview),
            onSuccess);
    };

    // Ingestion

    const onIngestionClickHandler = () => {
        setActiveTabId("tenth");
    };

    // Adjustment

    const [adjustmentFlashbar] = useState([]);


    const [activeTabId, setActiveTabId] = useState('first');

    // eslint-disable-next-line no-unused-vars
    // Checklist
    const [checklistState] = useState({
        preparer: {
            option1: stepsState.checklistStep.completed,
            option2: stepsState.checklistStep.completed,
            option3: stepsState.checklistStep.completed
        },
        approver: {
            option1: obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed,
            option2: obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed,
            option3: obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed
        }
    });


    // Load ErrorSummary
    const [transactionSummaryData, setTransactionSummaryData] = useState([]);
    useEffect(() => {
        const request = {
            parentWorkflowId: itemData?.parentWorkflowId,
            setItems: setTransactionSummaryData,
            setIsLoading: setIsTransactionReviewLoading,
            useCase: itemData?.type,
            phastosObligation: PHASTOS_OBLIGATION.DS
        };
        getExceptionTransactionDetails(request);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <AppLayout
                navigationHide={true}
                toolsHide={slackChannel === undefined}
                tools={
                        <Correspondence 
                            correspondenceMessages={correspondenceMessages}
                            correspondenceLoading={correspondenceLoading}
                            handleNewMessage={handleNewMessage}
                        />
                }
                onToolsChange={handleToolsChange}
                content={
                    <div className="phastos-exception">
                        <Flashbar items={adjustmentFlashbar}/>
                        <Flashbar items={exceptionSummaryData?.flashBarItem}/>
                        <Flashbar items={updateWorkflowStatus?.flashBarItem}/>
                        <SpaceBetween size='l'>
                            <div className="progress-card">
                                <ul id="progressbar">
                                    <li className="active clickable" id="ingestion" onClick={onIngestionClickHandler}><strong>Preparation</strong></li>
                                    <li className={obligationStatus === OBLIGATION_STATUS.UnderApproval || obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed ? "active" : obligationStatus === OBLIGATION_STATUS.UnderReview ? "semi-active" : ""} id="review"><strong>Review</strong></li>
                                    <li id="approve" className={obligationStatus === OBLIGATION_STATUS.UnderApproval ? "semi-active" : obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed ? "active" : ""}><strong>Approval</strong></li>
                                    <li id="filing_in_progress" className={obligationStatus === OBLIGATION_STATUS.UnderFiling ? "semi-active" : obligationStatus === OBLIGATION_STATUS.Filed ? "active" : ""}><strong>Filing</strong></li>
                                    <li id="filed" style={{ width: '0' }} className={obligationStatus === OBLIGATION_STATUS.Filed ? "active" : ""}><strong>Complete</strong></li>
                                </ul>
                            </div>
                            <ExpandableSection
                                variant="container"
                                header={<Header actions={<SpaceBetween direction="horizontal" size="xs">
                                    {userRole === REVIEWER_ROLE ? <SpaceBetween direction="horizontal" size="xs">
                                        <Button disabled={true}>Start filing</Button>
                                            <Button disabled={isSendForApproveButtonDisabled() || approveButtonDisabled}
                                                    onClick={sendForApproveButtonClickHandler}>Send for approval</Button>
                                        {(isSendForApproveButtonDisabled() || approveButtonDisabled) &&
                                            <Popover
                                                position="top"
                                                size="small"
                                                triggerType="custom"
                                                content={
                                                    <div>
                                                        All review step not yet completed.
                                                    </div>
                                                }
                                            >
                                                <Button iconName="status-info" variant="icon" />
                                            </Popover> }

                                    </SpaceBetween> :
                                   <SpaceBetween direction="horizontal" size="xs">
                                        <Button disabled={obligationStatus !== OBLIGATION_STATUS.UnderApproval} onClick={rejectButtonClickHandler}>Reject</Button>
                                        <Button disabled={isApproveButtonDisable()} onClick={approveButtonClickHandler}>Approve</Button>
                                       {!(checklistState.approver.option1 && checklistState.approver.option2 && checklistState.approver.option3) &&
                                       <Popover
                                           position="top"
                                           size="small"
                                           triggerType="custom"
                                           content={
                                               <div>
                                                   Checklist not yet completed.
                                               </div>
                                           }
                                       >
                                           <Button iconName="status-info" variant="icon" />
                                       </Popover>}
                                    </SpaceBetween>
                                    }
                                </SpaceBetween>}>Obligation details</Header>}
                            >
                                <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
                                    <ObligationDetails data={itemData} obligationStatus={obligationStatus} phastosObligation={PHASTOS_OBLIGATION.DS} />
                                    <ExceptionSummary exceptionSummaryTableProp={exceptionSummaryTableProp} />
                                </Grid>
                            </ExpandableSection>
                            <div>
                                <Tabs
                                    tabs={[
                                        {
                                            label: 'Summary',
                                            id: 'first',
                                            content: <Summary
                                                exceptionData={exceptionData}
                                                isUnderReview={isUnderReview}
                                                stepsState={stepsState}
                                                setStepsStateHandler={setStepStateHandler}
                                                updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                parentWorkflowId={itemData?.parentWorkflowId}
                                            />
                                        },
                                        {
                                            label: 'Checklists',
                                            id: 'second',
                                            content: <ExceptionChecklists
                                            exceptionData={exceptionData}
                                            isUnderReview={isUnderReview}
                                            stepsState={stepsState}
                                            setStepsStateHandler={setStepStateHandler}
                                            updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                            parentWorkflowId={parentWorkflowId} />
                                        },
                                        {
                                            label: 'Transaction review',
                                            id: 'third',
                                            content:
                                                <Box>
                                                    <ErrorSummary
                                                        exceptionData={exceptionData}
                                                        isUnderReview={isUnderReview}
                                                        stepsState={stepsState}
                                                        obligationStatus={obligationStatus}
                                                        quicksightLinkMap={quickSightLinkMap}
                                                        quicksightEmbedLinks={quicksightEmbedLinks}
                                                        setStepsStateHandler={setStepStateHandler}
                                                        updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                        parentWorkflowId={itemData?.parentWorkflowId}
                                                        slackChannel={slackChannel}
                                                        transactionReviewEditMode={transactionReviewEditMode}
                                                        setTransactionReviewEditMode={setTransactionReviewEditMode}
                                                        transactionSummaryData={transactionSummaryData}
                                                        isTransactionReviewLoading={isTransactionReviewLoading}
                                                        setIsTransactionReviewLoading={setIsTransactionReviewLoading}
                                                        useCase={itemData?.type}
                                                        reportingPeriod={itemData?.reportingPeriod}
                                                        useCaseType={itemData?.identifierMap?.useCaseType}
                                                        phastosUseCaseName={itemData?.phastosUseCaseName}
                                                        domain={domain}
                                                        phastosObligation={PHASTOS_OBLIGATION.MOG}
                                                    />
                                                </Box>
                                        },
                                        {
                                            label: 'Audit',
                                            id: 'fourth',
                                            content: <Adjustments parentWorkflowId={itemData?.parentWorkflowId} isUnderReview={isUnderReview}
                                                                  stepsState={stepsState}
                                                                  setStepsStateHandler={setStepStateHandler}
                                                                  updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                                  slackChannel={slackChannel} />,
                                        },
                                        {
                                            label: 'Attachments',
                                            id: 'fifth',
                                            content: <ViewAttachments
                                                parentWorkflowId={itemData?.parentWorkflowId}
                                                useCase={itemData?.type}
                                                slackChannel={slackChannel}
                                            />,
                                            disabled: false
                                        },
                                        {
                                            label: 'Trends',
                                            id: 'sixth',
                                            content: <></>,
                                            disabled: true
                                        },
                                        {
                                            label: 'Filing',
                                            id: 'seventh',
                                            content: <></>,
                                            disabled: true,
                                        }
                                    ]}
                                    activeTabId={activeTabId}
                                    onChange={(event) => {setActiveTabId(event.detail.activeTabId)}}
                                />
                            </div>
                        </SpaceBetween>
                        <RejectReturnModal 
                            rejectConfirmModal={rejectConfirmModal}
                            rejectConfirmHandler={rejectConfirmHandler}
                            setRejectConfirmModal={setRejectConfirmModal}
                        />
                    </div>
                }
            />
        </>
    );
}

export default Exception;
