import {get, isNull, isUndefined} from "lodash";
import Auth from "@aws-amplify/auth";
import {PHASTOS_OBLIGATION} from "../components/common/constants";
import * as XLSX from 'xlsx';

export const getQuickSightLinkMap = (data) => {
    const quickSightLinkMap = get(data, 'quickSightLinkMap', []);
    const updatedQuickSightLinksMap = {};
    if (isNull(quickSightLinkMap)) {
        return null;
    }
    quickSightLinkMap.forEach(quickSightLink => {
        updatedQuickSightLinksMap[quickSightLink.text] = {
            dashboardId : quickSightLink.id,
            serviceName : quickSightLink.serviceName
        };
    });
    return updatedQuickSightLinksMap;
};

export const getUserAlias = async () => {
    let name = ""
    await Auth.currentAuthenticatedUser().then((userInfo) => {
        const username = userInfo.username?.split('_');
        name = username.length > 1 ? username[1] : username;
    });
    return name;
}

export const isObligationUnderReview = (status) => {
    return status === 'UNDER_REVIEW';
}

export const isAcknowledgeCompleted = (status) => {
    return status === "COMPLETE";
}

export const getFormattedDate = (timestamp) => {
    if (isUndefined(timestamp)) return '-';
    return new Date(Number.parseInt(timestamp)).toLocaleDateString();
}

export const getFormatedUserName = (user) => {
    if (isUndefined(user)) return '-';
    return user;
}

export const getAcknowledgeData = (acknowledgeMap) => {
    return {
        summaryStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.SUMMARY?.status),
            user: getFormatedUserName(acknowledgeMap?.SUMMARY?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.SUMMARY?.lastUpdate)
        },
        exceptionReviewStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.EXCEPTIONS?.status),
            user: getFormatedUserName(acknowledgeMap?.EXCEPTIONS?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.EXCEPTIONS?.lastUpdate)
        },
        trendsStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.TRENDS?.status),
            user: getFormatedUserName(acknowledgeMap?.TRENDS?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.TRENDS?.lastUpdate)
        },
        checklistStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.CHECKLIST?.status),
            user: getFormatedUserName(acknowledgeMap?.CHECKLIST?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.CHECKLIST?.lastUpdate)
        },
        adjustmentStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.ADJUSTMENT?.status),
            user: getFormatedUserName(acknowledgeMap?.ADJUSTMENT?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.ADJUSTMENT?.lastUpdate)
        },
    };
}

export const getAcknowledgeDataDSV2 = (acknowledgeMap) => {
    return {
        summaryStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.SUMMARY?.status),
            user: getFormatedUserName(acknowledgeMap?.SUMMARY?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.SUMMARY?.lastUpdate)
        },
        checklistStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.CHECKLIST?.status),
            user: getFormatedUserName(acknowledgeMap?.CHECKLIST?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.CHECKLIST?.lastUpdate)
        },
        adjustmentStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.ADJUSTMENT?.status),
            user: getFormatedUserName(acknowledgeMap?.ADJUSTMENT?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.ADJUSTMENT?.lastUpdate)
        },
        auditStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.AUDIT?.status),
            user: getFormatedUserName(acknowledgeMap?.AUDIT?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.AUDIT?.lastUpdate)
        },
    };
}

export const getAcknowledgeDataForVAT = (acknowledgeMap) => {
    return {
        summaryStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.SUMMARY?.status),
            user: getFormatedUserName(acknowledgeMap?.SUMMARY?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.SUMMARY?.lastUpdate)
        },
        transactionReviewStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.TRANSACTION_REVIEW?.status),
            user: getFormatedUserName(acknowledgeMap?.TRANSACTION_REVIEW?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.TRANSACTION_REVIEW?.lastUpdate)
        },
        insightStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.INSIGHT?.status),
            user: getFormatedUserName(acknowledgeMap?.INSIGHT?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.INSIGHT?.lastUpdate)
        },
        auditStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.AUDIT?.status),
            user: getFormatedUserName(acknowledgeMap?.AUDIT?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.AUDIT?.lastUpdate)
        },
        checklistStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.CHECKLIST?.status),
            user: getFormatedUserName(acknowledgeMap?.CHECKLIST?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.CHECKLIST?.lastUpdate)
        },
    };
}

export const getPhastosObligationFromWorkflowType = (workflowType) => {
    switch (workflowType) {
        case "INTRASTAT":
            return PHASTOS_OBLIGATION.MOG;
        case "DATA_SHARING":
            return PHASTOS_OBLIGATION.DS;
        case 'DIGITAL_SERVICES_TAX':
            return PHASTOS_OBLIGATION.DST;
        case "VAT_RETURN":
        default:
            return PHASTOS_OBLIGATION.VAT;
    }
};

export const getObligationName = (data, phastosObligation) => {
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return `${data?.obligationName} ${data?.identifierMap?.useCaseType}`;
        default:
            return `${data?.obligationName}`;
    }
};

export const downloadJsonAsExcel = (jsonData, fileName) => {

    const workSheet = XLSX.utils.json_to_sheet(jsonData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, fileName);
    const buff = XLSX.write(workBook, {type: "buffer", bookType: "xlsx"})
    const blob = new Blob([buff], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    const formattedDate = getCurrentDateTime();
    fileName = `${fileName}_${formattedDate}`
    a.href = url;
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};

export const getCurrentDateTime = () => {
    const currentDate = new Date();
    const dateString = currentDate.toLocaleDateString();
    const timeString = currentDate.toLocaleTimeString();
    return `${dateString}_${timeString}`;
};

export const convertStringToPercentage = (input) => {
    const number = parseFloat(input);
    if (isNaN(number) || !isFinite(number)) {
        return '-';
    }
    return (number * 100).toFixed(4) + '%';
}

export const checkValidNumberInputAndDisplayErrorMessage = (value, setMessage, errorMessage) => {
    if (isNaN(value) || !isValidFloatNumber(value)) {
        setMessage(errorMessage)
        return false;
    } else {
        setMessage("");
        return true;
    }
};

export const isValidFloatNumber = (input) =>{
    return /^(?!-)\d+(\.\d+)?$/.test(input);
}

export const convertStringToFloatWithPrecision = (inputString, precision) =>{
    let numberValue = parseFloat(inputString);
    if (isNaN(numberValue)) {
        return "-";
    }
    return numberValue.toFixed(precision);
}
