import { Container, Box, Header } from "@amzn/awsui-components-react/polaris";
import {USE_CASE_CONFIG, FILING_CONFIG} from "../utils/Constants"

//TODO : create API to fetch available use-cases
export function getDropdownItems() {
    return USE_CASE_CONFIG;
}

export const getFilingInstruction = (useCase) => {
    const instruction  = useCase ? FILING_CONFIG[useCase]?.instructions : null;
    if(instruction){
        return (
            <Container>
                <Header variant="h3">Instructions</Header>
                {instruction.map(function(data,idx){
                    return (<li key={idx}>{data}</li>)
                })}
            </Container>
          ); 
    }
}

export let fetchCountry = (useCase) => {
    if (useCase === "FR")
        return "FRANCE";
    else if (useCase === "NOR")
        return "NORWAY";
    else if (useCase === "AU")
        return "AUSTRIA";    
    else if (useCase === "UK_VAT_FILING")
        return "UK_VAT_FILING";
    else if (useCase === "IS_VAT_FILING")
        return "IS_VAT_FILING";
    else if (useCase === "DE_INTRASTAT_FILING")
        return "DE_INTRASTAT_FILING";
    else if (useCase === "IR_MOSS_FILING")
        return "IR_MOSS_FILING";
    else if (useCase === "DE_VAT_FILING")
        return "DE_VAT_FILING";
    else if (useCase === "TR_DST_FILING")
        return "TR_DST_FILING";
    else if (useCase === "TR_VAT_FILING")
        return "TR_VAT_FILING";
    else
        return "Compliance";
}

// Cut long float values update X decimal places
export const convertToXDecimalPlaces = (num, digits) => {
    if (typeof num == 'number' && !isNaN(num) && !Number.isInteger(num)) {
        return parseFloat(num.toFixed(digits))
    }
    return num
}

export const getStartEndDateOfFiscalYear = () => {
    let today = new Date();

    // start date = today - 1 year
    let date = new Date(today);
    date.setFullYear(date.getFullYear()-1);
    date.setDate(date.getDate()+1);
    let startDate = date.toISOString().split('T')[0];

    let endDate = today.toISOString().split('T')[0];
    return { startDate, endDate }
}

export const populateReportingPeriod = (data) => {
    if(!data || !data.length)
    return;
    let result = [];
    for (let i = 0; i < data.length; i++) {
        let text;
        if(data[i].status === "F"){
            text = data[i].startDate + " - " + data[i].endDate + " (FULFILLED)";
        } else {
            text = data[i].startDate + " - " + data[i].endDate
        }
        result.push({ id: i + 1, text: text, periodKey: data[i].periodKey, disabled: data[i].status === "F" })
    }
    return result;
}

export const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
)

export function getReadableFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);
  
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }
